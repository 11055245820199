.HostImageStyle {
  width: 100px;
  height: 31px;
}

.HostImageBoxStyle {
  margin-left: 4%;
  margin-right: 4%;
}

.HostCheckBoxStyle {
  height: 5%;
  width: 5%;
  margin-top: -1%;
}

.HostPanelBoxStyle {
  margin-top: 5%;
}

.HostContainer {
  padding: 20px;
}

.AccordionDetailsStyle {
  padding: 0 !important;
}

.HostCardStyle {
  min-height: 150px;
  padding: 0px 0px 20px 5px;
}

.NoHostContainer {
  margin-left: 25%;
  margin-top: 15%;
  font-weight: 800;
  font-size: larger;
}

.ShazamHostImageStyle {
  width: 100px;
  height: 25px;
}
