.TransactionIdStyle {
  width: 52%;
}

.TransactionTypeStyle {
  margin-right: 2% !important;
  width: 25%;
}

.PaymentMethodStyle {
  width: 24%;
  margin-right: 1% !important;
}

.TransactionIdInputStyle,
.RoutingNumberInputStyle,
.AccountNumberInputStyle,
.TransactionTypeInputStyle,
.PaymentMethodInputStyle {
  background: #ffffff;
}

.AccountNumberStyle {
  margin-right: 4% !important;
  width: 48%;
}

.RoutingNumberStyle {
  width: 48%;
}

.BankAccountDetailsBoxStyle {
  width: 52%;
}