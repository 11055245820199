.CreditCardInputStyle {
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 10px !important;
  background: #ffffff;
}

.CreditCardInputStyle .MuiInputLabel-root {
  pointer-events: none;
  background-color: #ffffff;
  padding: 0 4px;
}

.CreditCardInputStyle .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: blue;
  /* Set the desired outline color (e.g., blue) */
}


.CreditCardContainerStyle {
  width: 52%;
}

.CreditCardStyle {
  width: 60%;

  fieldset {
    border: unset !important;
  }
}

.Section1 {
  width: 48%;
  margin-right: 2% !important;
}

.Section2 {
  width: 50%;
}

.ExpiryDateStyle {
  width: 20%;

  fieldset {
    border: unset !important;
  }
}

.CvvStyle {
  width: 20%;

  fieldset {
    border: unset !important;
  }
}

.PaymentInfoBoxStyle {
  margin-top: 1rem;
  width: 100%;
}

.CreditInfoBoxStyle {
  margin-top: 1rem;
  width: 100%;
}

.CreditCardErrorStyle {
  color: red;
}