.AddressFullNameStyle {
  width: 100%;
  margin-right: 2% !important;
  background: #ffffff;
}

.StreeAddressStyle {
  width: 96%;
  margin-right: 2% !important;
  background: #ffffff;
}

.EmailAddressStyle {
  width: 96% !important;
  margin-right: 2% !important;
  background: #ffffff;
}

.AddressLineStyle {
  width: 100%;
  background: #ffffff;
}

.AddressStateStyle {
  width: 100%;
  background: #ffffff;
}

.AddressFieldLabelStyle {
  width: 100%;
}

.AddressCityStyle {
  width: 96%;
  margin-right: 2% !important;
  background: #ffffff;
}

.AddressCountryStyle {
  width: 100%;
  background: #ffffff;
}

.AddressZipCodeStyle {
  width: 96%;
  margin-right: 2% !important;
  background: #ffffff;
}

.FullNameStyle {
  background: #ffffff;
}

.Section1 {
  width: 48%;
  margin-right: 2% !important;
}

.Section2 {
  width: 50%;
}

.BillingInfoBoxStyle,
.ShippingInfoBoxStyle {
  width: 100%;
  background-color: transparent !important;
  box-shadow: unset !important;
  border: unset;
}